/* imports and utilities like animations and mixins should be defined here */
@import '~@xpo-ltl/ngx-ltl-core/styles/variables';
@import '~@xpo-ltl/ngx-ltl-core/styles/reset';
@import 'grid-styles';

$app-actionBar-height: 40px;

.app {
  &[class*='--hasActionBar'] {
    margin-bottom: $app-actionBar-height + 1;

    .app-ActionBar {
      margin-bottom: -$app-actionBar-height;
    }
  }
}

mat-form-field.mat-form-field-type-mat-input .mat-form-field-flex .mat-form-field-infix .mat-input-element:disabled {
  font-style: normal;
}

mat-form-field.mat-form-field-type-mat-input .mat-form-field-flex .mat-form-field-infix .mat-form-field-label-wrapper .mat-form-field-label {
  -webkit-transform: none !important;
  transform: none !important;
}

.line-break-snackBar {
  white-space: pre-line;
}

.medium-snackBar {
  @extend .line-break-snackBar;

  min-width: 50vw !important;
}
.large-snackBar {
  @extend .line-break-snackBar;

  min-width: 75vw !important;
}

.bullet-list {
  list-style-type: disc;
  list-style-position: inside;
}

input[type=search]::-webkit-search-cancel-button {
  -webkit-appearance: searchfield-cancel-button;
}

.tooltip {
  &--lg {
    max-width: 700px !important;
  }
}

.tabs .xpo-PillTabGroup .mat-tab-header .mat-tab-label.mat-tab-disabled {
  background-color: $xpo-white;
}
